<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('certificateshow')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('certificateshow')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :exportExcel="true"
                @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year"
                            ></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3">
                    <b-form-group :label="$t('semester')">
                        <semester-type-selectbox v-model="datatable.queryParams.filter.semester"></semester-type-selectbox>
                    </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>



        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox";
// Services
import StudentCertificateService from "@/services/StudentCertificateService";
// Others
import qs from 'qs';


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        AcademicYearsSelectbox,
        SemesterTypeSelectbox,
        DatatableFilter,
        Datatable,


    },
    metaInfo() {
        return {
            title: this.$t('certificateshow').toUpper()
        };
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [

                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.$t('student_program_id'),
                        field: 'student_program_id',
                        sortable: false,
                    },
                    {
                        label: this.$t('certificate_id'),
                        field: 'certificate_id',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            userId: null
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        exportExcel(){

        },
        filterSet() {
            this.datatable.queryParams.filter = {

            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return StudentCertificateService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Clear
        formClear() {
            this.formId = null;
            this.formProcess = null;
        },





    }
}
</script>

